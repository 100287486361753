import React, { Component } from 'react';
import { RandomQuotes } from '../randomQuotes/RandomQuotes.js';
import { Logo } from '../logo/Logo.js';
import { PlayerIcon } from '../playerIcon/PlayerIcon.js';
import { GameCode } from '../gameCode/GameCode.js';
import styles from './PlayersJoining.module.css';
import './Host.js';

export class PlayersJoining extends Component {
    getPlayerIconClass = () => {
        let RESIZE_CUTOFF_MED = 12
        let RESIZE_CUTOFF_LG = 4;

        if (this.props.game.players.length < RESIZE_CUTOFF_LG) {
            return "icon-lg";
        } else if (this.props.game.players.length < RESIZE_CUTOFF_MED) {
            return "icon-med";
        } else {
            return "icon-sm";
        }
    }

    getPlayerNameClass = () => {
        let RESIZE_CUTOFF = 9;
        return this.props.game.players.length > RESIZE_CUTOFF ?
            "Small" :
            "Med";
    }

    render() {
        return (
            <div className={styles.content}>
                <div className={styles.logoRow}>
                    <Logo />
                    <div className={styles.linkText}>
                        Join at
                        <br />
                        <span className={styles.qmotLink}>https://qmot.tv</span>
                    </div>
                    <GameCode className={styles.gameCode} gameCode={this.props.game.gameCode} />
                </div>
                <div className={styles.gameCodePlayerList}>
                    <div className={styles.playerList}>
                        {this.props.game.players.map((player) => {
                            return(
                                <div key={player.id} className={styles.player}>
                                    <div className={this.getPlayerIconClass()} >
                                        <PlayerIcon player={player} />
                                    </div>
                                    <p className={styles["playerName" + this.getPlayerNameClass()]}>{player.name}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        );
    }
}